import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Product, UppercaseProduct } from '@uc/web/shared/data-models';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({
	providedIn: 'root',
})
export class ProspectusExternalClickApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	prospectusClickLead(uniId: number, product: Product) {
		if (!uniId) return;

		const uppercaseProduct = product.toUpperCase() as UppercaseProduct;

		const params = {
			university_id: uniId,
			product: uppercaseProduct,
		};

		lastValueFrom(
			this._http.post(
				`${this._apiSrv.API_URL}leads/order-external-prospectus`,
				params,
			),
		)
			.then(console.log)
			.catch(console.error);
	}
}
