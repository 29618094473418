import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configureSource, EnvironmentApiService } from '@uc/utilities';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class VisitWebsiteBtnApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	/**
	 * @param uniId ID of uni tracked
	 * @param source name of uni or course name
	 * @param sourceType uni | course
	 * @returns int (I assume ID of last inserted row in DB)
	 */
	visitWebsiteClick(
		uniId: number,
		source: string,
		sourceType: SourceTrackingTypes = 'uni',
	) {
		const params = {
			university_id: uniId,
			source: configureSource(source, sourceType),
		};

		lastValueFrom(
			this._http.post(`${this._apiSrv.API_URL}leads/visit-website`, params),
		)
			.then(console.log)
			.catch(console.error);
	}
}
